/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our story
            </Typography>
            <Typography component={'p'}>
              We believe almost every small to medium size
              organization in the USA can claim the Employee Retention Credit (ERC).
              <br />
              <br />
              We designed and built a system that allows us to compute the ERC for
              any organization with fewer than 500 employees within 48 hours.
              <br />
              <br />
              At ERT.credit, you have a team of payroll tax specialists and technologists with over 40 years of 
              combined experience building automated systems that maximize benefits for our
              customers.
              <br />
              <br />
              Recently, many businesses have been told by their accountants and advisors that they do not qualify 
              for the ERC. When we looked into why, we found that because the eligibility rules recently changed, 
              a majority of business owners as well as accounting and tax advisory services are simply unaware.
              <br/>
              <br/>
              In addition, none of said business/services have a state-of-the-art automated system to get this tax credit 
              computed quickly, all the while maximizing the credit to make sure no money is left on the table. 
              As a result, many of these professionals and business owners are asking us to help them maximize <b>their</b> credit.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={500} width={1}>
            <Box
              component={'img'}
              src={
                'https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration1.svg'
              }
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
