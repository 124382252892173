/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ImageList, ImageListItem } from '@mui/material';

const mock = [
  'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fpage-backgrounds%2Frestaurants.jpg?alt=media&token=297ba4ff-e8a5-4323-b319-c9573c983fa9',
  'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fpage-backgrounds%2Fbars.jpg?alt=media&token=1c2e152b-c0ae-46b4-b9e1-b22615037432',
  'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fpage-backgrounds%2Fdoctor.jpg?alt=media&token=902fca87-8728-4403-801c-68b811d3c6fe',
  'https://firebasestorage.googleapis.com/v0/b/ertc-b0d32.appspot.com/o/public%2Fimages%2Fpage-backgrounds%2Fnursing-home.jpg?alt=media&token=2d1fe13b-16e3-4b02-b199-4280f9113782',
];

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    rows: 1,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    cols: 2,
    rows: 1
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    rows: 1,
    cols: 2
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    rows: 1,
    cols: 2
  }
];
const Partners = () => {
  const theme = useTheme();

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Trusted by 100's of small to medium sized businesses
            </Typography>
            <Typography variant={'h6'} component={'p'} color={'text.secondary'}>
              We have been doing payroll, tax, government credits, and building financial systems for over 40 years
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box display="flex" flexWrap="wrap" justifyContent={'flex-start'}>
              <ImageList
                sx={{ width: 500, height: 225 }}
                variant="quilted"
                cols={4}
                rowHeight={111}
              >
                {itemData.map((item, i) => (
                  <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                    <img
                      {...srcset(item.img, 111, item.rows, item.cols)}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Partners;
