/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const WhoWeAre = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              We want to make claiming your Employee Retention Credit simple
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              Getting the ball rolling is simple. We only require basic information about your organization
              that includes type of business, number of employees, PPP loans (if received) and ownership percentage.
              The process takes all of 5 minutes.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our process
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              We do all the heafy lifting. Our simple 8 step process makes it extremely efficient and quick. The process
              is completely electronic and requires very little work on the part of our clients. We support our
              clients through the process until we provide the final deliverables. We then stand by our clients for
              the entire duration of working with their payroll company and receiving the retention tax credit checks from the IRS.
              Our support line remains open around the clock.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoWeAre;
